import "./Header.css";

import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="header">
      <Link to="/">&#129526;</Link>
    </div>
  );
}
