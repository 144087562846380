import Footer from "../common/Footer";
import Header from "../common/Header";
import { Link } from "react-router-dom";

import "./Legal.css";

export default function Privacy() {
  let updatedDate = "September 17, 2024";
  return (
    <>
      <Header />
      <div className="legal">
        <h1>Privacy Policy</h1>
        <h2>Last updated {updatedDate}</h2>
        <div className="legal-body">
          <h2>Section 1 - Introduction</h2>
          <p>
            This Privacy Policy (Policy) outlines how your personal information
            is collected, held, used and disclosed when you access and use the
            Craftily App (“App”), provided by Roggue Technologies, LLC ("we",
            "us", "our").
          </p>
          <p>
            By accessing and/or using the App, or otherwise providing personal
            information to us, you acknowledge the collection and use of such
            information by us, in accordance with this Policy.
          </p>
          <p>
            This Policy should be read in conjunction with the terms in our End
            User License Agreement, which can be found{" "}
            <Link to="/legal/terms">here</Link>.
          </p>

          <h2>Section 2 - Collection of personal information</h2>
          <p>
            Generally, you can use and access the App without providing your
            personal information to us. However, there are some situations where
            we may collect limited forms of personal information, or
            non-identifiable information from you.
          </p>
          <p>
            When accessing and using the App, we may collect technical data and
            related information from your device, including:
            <ul>
              <li>
                details about your device, such as your device name, operating
                system version, and the configuration of the App on your device;
              </li>
              {/* ADD THIS BACK IN IF ADDING ICLOUD SYNCING */}
              {/* <li>
                details about your iCloud account (such as your iCloud storage
                capacity); and
              </li> */}
              <li>
                analytics and usage information about your use of the App.
              </li>
            </ul>
          </p>
          {/* ADD THIS BACK IN IF ADDING ICLOUD SYNCING */}
          {/* <p>
            When you input information into the App (either manually, via URL
            link, via your device camera, or through an image), this information
            is stored in your iCloud account, and we do not have access to this.
          </p> */}
          <p>
            Our App may require access to some of your smartphone device
            features and applications (such as the camera), which helps us to
            deliver certain features and the functionality of the App. We do not
            collect or store personal information from the App’s use of your
            device’s features and applications.
          </p>
          <p>
            When you contact us with requests, inquiries, or questions relating
            to the App, we may collect personal information that you provide
            voluntarily to us, such as your name and contact details (including
            email address and/or phone number).
          </p>
          {/* ADD BACK IF ADDING IN APP PURCHASES */}
          {/* <p>
            If you sign up for a subscription that allows you to access to
            certain features of the App, or if you make an in-App purchase, we
            do not collect or store your payment information. Your payment
            information is collected and stored by Apple, and is subject to
            their Privacy Policy, which you can find here.
          </p> */}
          <p>
            You can always choose to decline to provide your personal
            information to us. However, this may affect us responding to you, or
            us otherwise carrying out our general functions.
          </p>

          <h2>Section 3 - How we use your personal information</h2>
          <p></p>
          <p>
            Personal information will be used for the purposes of providing,
            operating, supporting, and maintaining the App, and for any other
            purpose specified in this Policy.
          </p>
          <p>
            We may use your personal information:
            <ul>
              <li>for internal administrative purposes;</li>
              <li>
                to respond to your requests, inquiries, feedback or customer
                service related activities;
              </li>
              <li>
                to improve the quality of the App, and any associated products
                or services we may offer in connection with the App;
              </li>
              <li>for marketing, statistical and research purposes;</li>
              <li>
                for related purposes for which you would reasonably expect us to
                use or disclose the information;
              </li>
              <li>
                where you have consented to us using or disclosing the
                information; or
              </li>
              <li>
                where we are required or otherwise permitted to do so by law.
              </li>
            </ul>
          </p>

          <h2>Section 4 - Disclosure of your personal information</h2>
          <p></p>
          <p>
            We do not sell, rent, lend, exchange or trade your personal
            information. In limited circumstances, we may disclose your personal
            information to third parties for purposes reasonably connected with
            the purposes for which we may use your personal information. For
            example, we may disclose your personal information to:
            <ul>
              <li>
                third-party service providers (such as analytics providers); or
              </li>
              <li>professional services advisors.</li>
            </ul>
          </p>

          <h2>Section 5 - Disclosure of your personal information</h2>
          <p></p>
          <p>
            We will take reasonable steps to protect your personal information
            against unauthorised access or disclosure while it is held by us.
            However, we cannot guarantee the absolute security of personal
            information transmitted over the Internet.
          </p>
          <p>
            The App may contain links to third party websites that are not
            operated by us. We suggest you review the privacy policy of every
            website you visit, and we have no control over or assume any
            responsibility for the content, privacy policies, or privacy
            practices of any third-party websites.
          </p>

          <h2>Section 6 - Retention of personal information</h2>
          <p></p>
          <p>
            We will not keep your personal information for longer than we need
            to. In most cases, this means that we will only retain your personal
            information for the duration of your relationship with us unless we
            are required to retain your personal information to comply with
            applicable laws, for example record-keeping obligations.
          </p>

          <h2>
            Section 7 - Accuracy, and your right to access or correct personal
            information
          </h2>
          <p>
            We take all reasonable steps to ensure that the personal information
            we hold is accurate, up to date, complete, relevant, and not
            misleading
          </p>
          <p>
            If you believe your personal information held by us is not accurate,
            complete, or otherwise requires amendment, please contact us at
            contact@craftily.app.
          </p>

          <h2>Section 8 - Privacy inquiries and complaints</h2>
          <p>
            If you have any questions or wish to make a complaint in relation to
            your handling of your personal information, please contact us at
            contact@craftily.app.
          </p>

          <h2>Section 9 - Your CCPA Rights</h2>
          <p>
            CCPA provides California residents with particular rights regarding
            their personal information collected by businesses. If you are a
            California resident, you may exercise the following rights regarding
            your personal information:
            <ul>
              <li>
                Right to Know: You may request an overview of the personal
                information we have collected about you in your account. You may
                request a list of the companies with which we share personal
                information in the course of providing our service to you.
              </li>
              <li>
                Right to Delete: You may request deletion of the personal
                information Roggue Technologies, LLC has collected about you in
                your account, for any reason. However, we may retain certain
                information as required or permitted by applicable law. To make
                such a request, please contact us at contact@craftily.app.
              </li>
            </ul>
          </p>

          <h2>Section 10 - Notice to residents of the European Union</h2>
          <p>
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your personal
            information be corrected, updated, or deleted. If you would like to
            exercise this right, please contact us through the contact
            information below. Additionally, if you are a European resident we
            note that we are processing your information in order to fulfill
            contracts we might have with you (for example if you make use of the
            App), or otherwise to pursue our legitimate business interests
            listed above. Additionally, please note that your information will
            be transferred outside of Europe, including to Canada and the United
            States.
          </p>

          <h2>Section 11 - Children's privacy</h2>
          <p>
            Only persons age 13 or older have permission to access the App. We
            do not knowingly collect personally identifiable information from
            children under 13. If you are a parent or guardian and you learn
            that your Children have provided us with Personal Information,
            please contact us. If we become aware that we have collected
            Personal Information from a child under age 13 without verification
            of parental consent, or any other individual under the age of 13, we
            take steps to remove that information from our servers.
          </p>

          <h2>Section 12 - Changes to this Policy</h2>
          <p>
            We may update our Privacy Policy from time to time by posting a new
            version on our website.
          </p>
          <p>
            Any change that we make applies from the date we post it on the
            website, and we recommend that you review this Policy periodically
            for any changes. Your continued use of the App constitutes
            acknowledgement of this Policy as updated.
          </p>
        </div>
      </div>
      <Footer fixed={false} />
    </>
  );
}
