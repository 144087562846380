import Footer from "../common/Footer";

import "./Home.css";

export default function Home() {
  return (
    <>
      <div className="title-container">
        <h2>Come back soon to learn more about</h2>
        <h1>craftily</h1>
      </div>
      <div className="feature-container">
        <p>&#129526;</p>
      </div>
      <Footer />
    </>
  );
}
