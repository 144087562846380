import Footer from "../common/Footer";
import Header from "../common/Header";

import "./Legal.css";

export default function Terms() {
  let updateDate = "September 17, 2024";
  return (
    <>
      <Header />
      <div className="legal">
        <h1>End User License Agreement</h1>
        <h2>Last updated {updateDate}</h2>
        <div className="legal-body">
          <h2>Introduction</h2>
          <p>
            The Craftily App (“App”) is made available through the App Store
            through a license (“License”), and it is not sold to you. Your
            License is subject to this Custom End User License Agreement
            (“Custom EULA”) between you and the provider of the App, being
            Roggue Technologies, LLC (“we", “us”, "our"). This Custom EULA forms
            a legal agreement between you and the us. This Custom EULA does not
            apply to Apple, and we are solely responsible for the App. Apple has
            the right to enforce this Custom EULA against you as a third-party
            beneficiary relating to your use of the App.
          </p>
          <p>
            This Custom EULA does not provide usage rules for apps that are in
            conflict with the Apple Media Services Terms and Conditions as of{" "}
            {updateDate}. By downloading, accessing and using the App, both we
            and you acknowledge that you have had the opportunity to review the
            Apple Media Services Terms and Conditions found here.
          </p>
          <h2>Section 1 - Acceptance of the terms</h2>
          <p>
            By downloading, accessing, and/or using the App, you agree to be
            bound by this Custom EULA. The Custom EULA takes effect from the
            date that you download the App. If you do not accept this Custom
            EULA, then you must not access or use the App.
          </p>
          <p>
            We reserve the right to update, change or replace any part of these
            terms by posting updates and/or changes on our website or in the
            App. Your continued use of, or access to, the App following the
            posting of any changes constitutes acceptance of the updated terms.
            If you do not agree to any updated terms, you must cease using the
            App.
          </p>
          <h2>Section 2 - Scope of the License</h2>
          <p>
            We grant you a non-transferable and non-exclusive license to use the
            App on any Apple-branded products that you own or control and as
            permitted by the Usage Rules set out in the Apple Media Services
            Terms and Conditions, except that the App may be accessed and used
            by other accounts associated with you via Family Sharing or volume
            purchasing.
          </p>
          <p>
            The terms of this Custom EULA will govern any content, materials, or
            services accessible from or purchased within the App as well as
            upgrades provided by us that replace or supplement the original App
            (unless such an upgrade is accompanied by its own EULA).
          </p>
          <h2>Section 3 - Consent to use of data and personal information</h2>
          <p>
            We are committed to protecting your privacy. By downloading,
            accessing, and/or using the App, you acknowledge the collection,
            use, storage and disclosure of your personal information, as
            outlined in the App’s Privacy Policy located here.
          </p>
          <p>
            We may collect and use technical data and related information,
            including technical information about your device, system and
            application software, and peripherals that is gathered periodically
            to facilitate the provision of software updates, product support,
            and other services to you (if any) related to the App. We may use
            this information (in a form that does not personally identify you)
            to improve the App, our products or to provide services or
            technologies to you.
          </p>
          <h2>Section 4- Use of the App</h2>
          <p>When using the App, you must:</p>
          <p>
            <ul>
              <li>do so for personal, non-commercial purposes only;</li>
              <li>do so for a lawful purpose only; </li>
              <li>follow any reasonable directions given by us; </li>
              <li>
                not attempt to modify, copy, adapt, reproduce, disassemble,
                decompile or reverse engineer any aspect of the App;
              </li>
              <li>
                not disable, avoid, or circumvent any security or access
                restrictions of the App;
              </li>
              <li>
                do so in accordance with applicable third-party terms of
                agreement; and
              </li>
              <li>
                not submit, upload or add User Content or Third Party Content
                that violates the law, infringes anyone’s intellectual property
                rights, violates anyone’s privacy, or breaches any agreements
                you have with others.
              </li>
            </ul>
          </p>
          <h2>Section 5 - User Content </h2>
          <p>
            The App may also allow you to submit, upload, store, share, post,
            link, or make available certain information (“User Content”).
          </p>
          <p>
            You are solely responsible for the User Content that you submit,
            upload, store or add to the App (or share, post, or link via the
            App) and we take no responsibility and assume no liability for User
            Content.
          </p>
          <p>
            By providing User Content in any way:
            <ul>
              <li>
                you represent that the User Content is yours, or you have the
                right to use it, and that the User Content complies with these
                terms; and
              </li>
              <li>
                you grant us the right and license to use, modify, perform,
                display, reproduce and distribute such User Content on the App
              </li>
            </ul>
          </p>
          <p>
            We have the sole discretion to remove User Content from the App that
            we deem violates the provisions of this Custom EULA, or for any
            other reason, to the extent that we have access to such User
            Content. We do not guarantee to you that you will have any recourse
            to edit, delete, or recover any User Content that you have
            submitted, uploaded, stored or added to the App. We have no
            liability to you for any User Content submitted by other users of
            the App, that may be made available to you.
          </p>
          <h2>Section 6 - Third Party Content</h2>
          <p>
            Through your use of the App, it may display, include or allow you to
            access third-party content, or provide links to third-party websites
            (“Third Party Content”). Third Party Content is not under our
            control and is solely made available for general information
            purposes.
          </p>
          <p>
            We do not endorse the contents of Third Party Content, and do not
            check its accuracy, completeness and reliability. We do not have any
            liability or responsibility to you or to any third-party in relation
            to Third Party Content, and your access to and reliance on Third
            Party Content is at your own risk and subject to any third parties’
            terms and conditions.
          </p>
          <h2>Section 7 - Warranty</h2>
          <p>
            We disclaim all warranties in relation to the App to the maximum
            extent permitted by applicable law. To the extent that any warranty
            exists under such law that cannot be disclaimed, we (and not Apple)
            shall be solely responsible for any warranty.
          </p>
          <p>
            In the event of any failure of the App to conform to any applicable
            warranty, you may notify Apple, and Apple will refund the purchase
            price for the App to you. To the maximum extent permitted by
            applicable law, Apple will have no other warranty obligation
            whatsoever with respect to the App, and any other claims, losses,
            liabilities, damages, costs or expenses attributable to any failure
            to conform to any warranty will be your sole responsibility.
          </p>
          <h2>Section 8 - Maintenance and Support</h2>
          <p>
            We (and not Apple) is solely responsible for providing any
            maintenance and support services in relation to the App, as
            specified in this EULA or as required by law.
          </p>
          <p>
            We reserve the right to modify, add, or remove parts of the App at
            any time. We may introduce improvements and new features from time
            to time into the App, some of which may be made immediately
            available.
          </p>
          <p>
            We will not be liable to you or to any third party for any
            modification, change, suspension or discontinuance of the App or any
            part of it.
          </p>
          <h2>Section 9 - Product claims</h2>
          <p>
            Any claim by you or a third party relating to the App, or your
            possession and/or use of the App are governed by these terms (and
            Apple is not responsible for such claims), including, but not
            limited to:
            <ul>
              <li>product liability claims;</li>
              <li>
                any claim that the App fails to conform to any applicable legal
                or regulatory requirement; and/or
              </li>
              <li>
                claims arising under consumer protection, privacy, or similar
                legislation.
              </li>
            </ul>
          </p>
          <h2>Section 10 - Legal compliance</h2>
          <p>
            You represent and warrant that:
            <ul>
              <li>
                you are not located in a country that is subject to a United
                States Government embargo, or that has been designated by the
                United States Government as a “terrorist supporting” country;
                and
              </li>
              <li>
                you are not listed on any United States Government list of
                prohibited or restricted parties.
              </li>
            </ul>
          </p>
          <h2>Section 11 - Liability</h2>
          <p>
            To the maximum extent permitted by law:{" "}
            <ul>
              <li>
                We, and our affiliates, agents, contractors, suppliers, service
                providers or licensors will not be liable to you for any injury,
                loss, claim, or any indirect, incidental, punitive, special, or
                consequential damages of any kind, including, without
                limitation, lost profits, lost revenue, lost savings, loss of
                data, replacement costs, or any similar damages, whether based
                in contract, tort (including negligence), strict liability or
                otherwise, arising from or relating to these terms or your use
                of the App, including, but not limited to, any errors or
                omissions in any User Content or Third Party Content, even if we
                have been advised of the possibility of such damages; and
              </li>
              <li>
                Our total liability to you for all damages will not exceed the
                amount paid by you to use the App in the three months
                immediately preceding your claim.
              </li>
            </ul>
          </p>
          <h2>Section 12 - Indemnity</h2>
          <p>
            You shall indemnify and hold harmless, us, our agents and affiliates
            from and against any and all third-party claims, liabilities, losses
            and expenses (including all associated actual legal costs), due to,
            arising out of or in connection with, your:
            <ul>
              <li>use of the App;</li>
              <li>violation of the terms of this Custom EULA; or</li>
              <li>
                violation of any law or the rights (including intellectual
                property rights) of a third party.
              </li>
            </ul>
          </p>
          <p>
            You acknowledge and accept that we may, at our option, control the
            investigation, defence and settlement of legal claims arising out of
            or in connection with your use of the App and you shall cooperate
            with us in respect of such investigation, defence and/or settlement
            where reasonably requested.
          </p>
          <h2>Section 13 - Intellectual Property</h2>
          <p>
            Nothing in this Custom EULA gives you any ownership rights over the
            App, nor any aspect of the App, including User Content.
          </p>
          <p>
            We (and not Apple) are responsible for addressing any claims by you
            or a third party in connection with the App (including any claims by
            a third party that the App breaches that third parties’ intellectual
            property rights).
          </p>
          <p>
            Where you provide any suggestions or feedback to us about the App,
            you acknowledge and agree that we may freely use and disclose all
            such suggestions and feedback as we see fit for any purpose.
          </p>
          <p>
            If you believe that any materials made available on the App infringe
            your intellectual property rights, please contact us on
            contact@craftily.app with details of your concerns. We take the
            protection of intellectual property rights seriously and will
            investigate in accordance with our procedures.
          </p>
          <h2>Section 14 - Suspension and Termination</h2>
          <p>
            This Custom EULA is effective until terminated by you or by us. Your
            rights under this Custom EULA will terminate automatically if you
            fail to comply or breach any of the terms of this Custom EULA.
          </p>
          <p>
            If we reasonably consider in our sole discretion that you have
            breached any of these terms, we may immediately and without notice
            take all or any of the following actions:
            <ul>
              <li>issue a warning to you;</li>
              <li>
                immediate, temporary, or permanent suspension or deactivation of
                your ability to use the App;
              </li>
              <li>
                place any restriction on your access to the App (or part of it);
                and/or
              </li>
              <li>
                disclose such information to law enforcement authorities as we
                reasonably feel is necessary.
              </li>
            </ul>
          </p>
          <h2>Section 15 - General</h2>
          <p>
            The terms of this Custom EULA will be governed by, and construed in
            accordance with, the laws of The United States.
          </p>
          <p>
            These terms (together with any other terms and conditions notified
            by us to you) constitute the entire agreement between us and you
            regarding your use of the App and supersede all prior arrangements,
            agreements and understandings between you and us.
          </p>
          <p>
            Any delay or failure to enforce our rights under these terms does
            not mean we have waived those rights.
          </p>
        </div>
      </div>
      <Footer fixed={false} />
    </>
  );
}
