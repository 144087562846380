import { Link } from "react-router-dom";

import "./Footer.css";

type FooterProps = {
  fixed: boolean;
};

export default function Footer(props: FooterProps) {
  return (
    <>
      <div id="footer" className={props.fixed ? "fixed" : ""}>
        <p>
          <Link to="/legal/terms">Terms & Conditions</Link> {"• "}
          <Link to="/legal/privacy">Privacy Policy</Link>
        </p>
        <p>&copy; Roggue Technologies, LLC</p>
      </div>
    </>
  );
}

Footer.defaultProps = {
  fixed: true,
};
